import { yupResolver } from '@hookform/resolvers/yup';
import Box, { type BoxProps } from '@mui/material/Box';
import CardActions, { type CardActionsProps } from '@mui/material/CardActions';
import CardContent, { type CardContentProps } from '@mui/material/CardContent';
import CardHeader, { type CardHeaderProps } from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider, { type DividerProps } from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Grid, { type GridProps } from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import { type CardLayoutProps } from 'components/layout/CardLayout';
import { type RenderSnackProps } from 'components/utils/renderSnack';
import useGetNestedObject from 'hooks/useGetNestedObject';
import usePreventDataLoss, { type PreventDataLossOptionsProps } from 'hooks/usePreventDataLoss';
import useTranslation from 'hooks/useTranslation';
import {
    Fragment,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useState,
    type ChangeEvent,
    type ElementType,
    type ForwardedRef,
    type ReactElement
} from 'react';
import {
    useForm,
    type DeepMap,
    type DeepPartial,
    type FieldError,
    type FieldValues,
    type FormState,
    type Path,
    type PathValue,
    type UseFormGetValues,
    type UseFormProps,
    type UseFormReset,
    type UseFormSetError,
    type UseFormTrigger
} from 'react-hook-form';
import { type ErrorResponseType } from 'services/types';
import { useIsMounted, useUpdateEffect } from 'usehooks-ts';
import * as Yup from 'yup';
import { uuid } from '../../utils/random';
import ErrorComponent from '../layout/ErrorComponent';
import LoadingComponent, { type LoadingComponentProps } from '../layout/LoadingComponent';
import FormCardHeader from './FVFormCardHeader';
import FormField, { type FormFieldProps } from './FVFormField';
import FormActionButton, { type FormActionButtonProps } from './FormActionButton';

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;

type DotNestedKeys<T> = (T extends object ?
    { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<keyof T, symbol>]
    : '') extends infer D ? Extract<D, string> : never;

interface TData<TDataShape> extends FormFieldProps {
    name: DotNestedKeys<TDataShape> & string
    value: FormFieldProps['value'],
}
interface TField<TDataShape> extends Partial<FormFieldProps> {
    name: unknown & string & DotNestedKeys<TDataShape & { [key: string]: any }>,
    component?: ElementType,
    GridProps?: GridProps & { component?: ElementType },
    lang?: string | any
}

const hashCode = (s: string) => s.split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0);

const defaultFormCardProps = {
    fields: [[]],
    itemKeyExtractor: ({ name }: { name: string }) => hashCode(name),
    getValidationRule: () => Yup.mixed().optional() as Yup.Schema
    // getValidationRule: () => rules.any as Yup.Schema
};

interface ActionDialogStateHandle<TDataShape extends FieldValues> extends Partial<FormState<TDataShape>> {
    update: (opt?: any) => void,
    trigger: UseFormTrigger<TDataShape>,
    reset: UseFormReset<TDataShape>,
    setValue: (propName: Path<TDataShape>) => (value: any) => Promise<void>,
    getValues: UseFormGetValues<TDataShape>,
    errors: any,
    setError: UseFormSetError<TDataShape>,
    notify: (dirty?: boolean) => void
    clearErrors: () => any,
    submit: () => Promise<unknown>,
    isValid: boolean
}

interface FormCardProps<TDataShape> {
    data?: TDataShape,
    fields?: TField<TDataShape>[][],
    readOnly?: boolean,
    itemKeyExtractor?: (item: TField<TDataShape>) => number | string,
    onSubmit?: (args: any) => any/*Promise<any | void | undefined>*/,
    onChange?: (args: any) => Promise<TDataShape | void | undefined>,
    getValidationRule?: (item: TField<TDataShape>) => Yup.Schema,
    dataLoading?: boolean,
    submitLoading?: boolean,
    header?: CardHeaderProps,
    actions?: { [key: string | symbol | number]: FormActionButtonProps },
    CardProps?: CardLayoutProps & { header?: CardHeaderProps & any } & any,
    DialogProps?: { [key: string]: any },
    CardContentProps?: CardContentProps & any,
    CardActionsProps?: CardActionsProps,
    CardActionsGridProps?: GridProps & { component?: ElementType },
    CardActionsGridItemProps?: GridProps & { component?: ElementType },
    SectionsProps?: GridProps & { component?: ElementType, sections?: TField<TDataShape>[][] | any },
    SectionDataWrapperProps?: Partial<BoxProps | GridProps>,
    GridItemsProps?: GridProps & { component?: ElementType } & { [key: string]: any },
    ItemsProps?: GridProps & { component?: ElementType } & { [key: string]: any },
    DividerProps?: DividerProps & { [key: string]: any },
    dialog?: boolean,
    open?: DialogProps['open'],
    onClose?: DialogProps['onClose'],
    isDirty?: boolean,
    createSnack?: (props: RenderSnackProps) => void,
    notifyOpt?: Partial<PreventDataLossOptionsProps & { isEqualToInitialData?: (_: TDataShape | any) => boolean }>,
    LoadingProps?: LoadingComponentProps,
    errors?: DeepMap<DeepPartial<TDataShape>, FieldError>
}

const FormCard = <TDataShape extends { [key: FormFieldProps['name']]: FormFieldProps['value'] }>({
    data = {} as TDataShape,
    fields,
    readOnly,
    onSubmit: parentSubmit,
    onChange,
    getValidationRule = defaultFormCardProps.getValidationRule,
    itemKeyExtractor = defaultFormCardProps.itemKeyExtractor,
    dataLoading,
    header,
    submitLoading,
    actions: baseActions,
    DialogProps,
    CardProps,
    CardContentProps,
    CardActionsProps,
    CardActionsGridProps,
    CardActionsGridItemProps,
    SectionsProps: { sections: SectionsUniqueProps, ...SectionsProps } = {},
    SectionDataWrapperProps,
    GridItemsProps,
    ItemsProps,
    DividerProps,
    dialog,
    open,
    onClose,
    notifyOpt = { enabled: false },
    isDirty: isOutsideDirty,
    createSnack,
    LoadingProps,
    errors: outsideErrors
}: FormCardProps<TDataShape>,
    ref: ForwardedRef<ActionDialogStateHandle<TDataShape>>) => {

    const [values, setValues] = useState<TDataShape>(data);
    const { t } = useTranslation();
    const isMounted = useIsMounted();
    const { getNestedObject } = useGetNestedObject();

    const validationRules = useMemo(
        () =>
            fields && fields.length
                ? [
                    ...fields
                        .flat(1)
                        .map((item) => item?.name ? ({
                            [item.name]:  getValidationRule(item)
                        }) : null)
                ].reduce<{ [key: string]: Yup.Schema }>(
                    (obj, item) => ({ ...obj, ...item }),
                    {}
                )
                : {},
        [fields, getValidationRule]
    );

    const validationSchema = useMemo(() => Yup.object().shape(validationRules), [validationRules]);

    const formOptions: UseFormProps<TDataShape, any> = useMemo(() => ({
        resolver: yupResolver(validationSchema),
        defaultValues: data as DeepPartial<TDataShape>
    }), [validationSchema, data]);

    const {
        register,
        handleSubmit,
        formState: { errors, dirtyFields, isValid, ...formState },
        setValue,
        setError,
        clearErrors,
        trigger,
        getValues,
        reset
    } = useForm<TDataShape>(formOptions);

    const tableId = useMemo(() => uuid(), []);

    const flatFields = useMemo(() => [...(fields || [])
        .flat(1)
        .map(item => item.name)
    ] as const, [fields]);

    const reduceDirtyFields = useCallback((df: any) => {
        return [...flatFields].reduce((a, c) => ({ ...a, ...(getNestedObject(df, c) ? ({ [c]: getNestedObject(df, c) }) : {}) }), {});
    }, [flatFields, getNestedObject]);

    const debouncedDF = useMemo(() => dirtyFields, [dirtyFields]);//useDebounce(dirtyFields, 64);
    const reducedDF = useMemo(() => {
        const red = reduceDirtyFields(debouncedDF);
        if (Object.keys(red).length > 0) {return red;}
        return false;
    }, [debouncedDF, reduceDirtyFields]);

    const isDirty = useMemo(() => notifyOpt?.enabled ? reducedDF || isOutsideDirty : false, [reducedDF, isOutsideDirty, notifyOpt?.enabled]);
    const notOpt = useMemo(() => ({ ...notifyOpt, id: tableId }), [notifyOpt, tableId]);

    const [notifyDirty, setNotify, resetNotify] = usePreventDataLoss(isDirty, notOpt);

    useUpdateEffect(() => {
        if (isMounted() && !notifyDirty?.[tableId]) {
            reset(obj => obj, {
                keepDirtyValues: false,
                keepDirty: false
            });
        }
    }, [isMounted, notifyDirty?.[tableId]]);

    const handleReset = useCallback((keepStateOption: any = {}) => {
        if (isMounted()) {
            reset(obj => obj, {
                keepDefaultValues: false,
                keepDirtyValues: false,
                keepDirty: false,
                ...keepStateOption
            });
            if (notifyOpt?.enabled) {
                resetNotify();
            }

        }
    }, [notifyOpt, resetNotify, reset, isMounted]);

    const handleValueChange = useCallback((prop: keyof TDataShape) => (event: ChangeEvent<HTMLInputElement> | any) => {
        setValues(({ [prop]: _oldValue, ...oldValues }) => ({ ...oldValues, [prop]: event.target.value }) as TDataShape);
        setValue(prop as Path<TDataShape>, event.target.value as PathValue<TDataShape, Path<TDataShape>>, { shouldDirty: true });
        if (errors[prop as Path<TDataShape>]) {
            trigger([prop as Path<TDataShape>]);
        }
        if (onChange) onChange({ [prop]: event.target.value });
    }, [setValue, errors, onChange, trigger]);

    const handleCheckedChange = useCallback((prop: keyof TDataShape) => (event: ChangeEvent<HTMLInputElement>) => {
        return handleValueChange(prop)({
            ...event,
            target: {
                ...event.target,
                value: event.target.checked
            }
        });
    }, [handleValueChange]);

    const onSubmit = useCallback((submitData: TDataShape) => {
        const controller = new AbortController();
        if (parentSubmit) {
            return parentSubmit({ ...submitData, signal: controller.signal, setValues })
                .then(() => {
                    if (isMounted()) {
                        handleReset();
                    }
                })
                .catch(({ errors: networkErrors, ...networkError }: ErrorResponseType = {}) => {
                    if (Object.keys(networkError).length > 0) {
                        console.warn({ networkError, networkErrors });//TODO: enelever
                        if (networkErrors != undefined) {
                            Object.entries<string[]>(networkErrors as { [key: string]: string[] }).map(([prop, hint]) => {
                                setError(prop as Path<TDataShape>, {
                                    type: 'network',
                                    message: (`layout.form.${hint[0]}`)
                                });
                            });
                        }
                        if (createSnack) {
                            createSnack(({ ...networkError, message: t((networkError as any)?.message || 'layout.error.network.unknown') }));
                        }
                    }
                    return networkError;
                });
        }
        return () => controller.abort();
    }, [parentSubmit, createSnack, t, setError, handleReset, isMounted]);

    const DefaultActionSubmit: FormActionButtonProps = useMemo(() => ({
        type: 'submit',
        isAllowed: true,
        loading: submitLoading,
        variant: 'contained',
        order: 99,
        text: t('layout.form.actions.submit')
    }), [submitLoading, t]);

    const actions: { [key: string]: FormActionButtonProps } = useMemo(() => ({
        ...baseActions,
        ...(baseActions?.submit ? ({
            submit: {
                ...DefaultActionSubmit,
                ...baseActions?.submit,
                ...(readOnly ? ({
                    disabled: true,
                    sx: {
                        visibility: 'collapse',
                        display: 'none'
                    }
                }) : null)
            }
        }) : null)
    }), [baseActions, readOnly, DefaultActionSubmit]);

    const imperativeSetValue = useCallback((prop: Path<TDataShape>) => async (value: any) => {
        setValues(({ [prop]: _oldValue, ...previousValues }) => ({ ...previousValues, [prop]: value }) as TDataShape);
        setValue(prop, value as PathValue<TDataShape, Path<TDataShape>>, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        });
        if (getNestedObject(errors, prop)) { trigger([prop ]); }
    }, [setValue, getNestedObject, errors, trigger]);

    const imperativeUpdateValues = useCallback(({ ...overwrite }: any = {}) => {
        if (isMounted()) {
            Object.entries({ ...data, ...overwrite }).map(([k, v]) => imperativeSetValue(k as Path<TDataShape>)(v));
        }
    }, [data, imperativeSetValue, isMounted]);

    const imperativeHandle: () => ActionDialogStateHandle<TDataShape> = useCallback(() => ({
        update: imperativeUpdateValues,
        trigger,
        setValue: imperativeSetValue,
        getValues,
        errors,
        setError,
        clearErrors,
        notify: handleReset,
        submit: handleSubmit(onSubmit),
        isValid,
        reset,
        ...formState
    }), [imperativeUpdateValues, imperativeSetValue, getValues, trigger, errors, reset, isValid, formState, setError, clearErrors, handleSubmit, onSubmit, handleReset]);

    useImperativeHandle(ref, imperativeHandle);

    const CardContentComponent = useMemo(() => dialog ? DialogContent : CardContent, [dialog]);
    const CardActionsComponent = useMemo(() => dialog ? DialogActions : CardActions, [dialog]);

    return (
        <>
            <FormCardHeader
                dialog={dialog}
                open={open}
                {...(dialog ? ({
                    onClose: onClose,
                    ...DialogProps
                }) : ({
                    ...CardProps
                }))}
                FormProps={{
                    ...((readOnly || !parentSubmit) ? ({
                        noForm: true
                    }) : ({
                        onSubmit: handleSubmit(onSubmit)
                    })
                    ),
                    ...(dialog ? DialogProps?.FormProps : CardProps?.FormProps)
                }}
            >
                {header ? (
                    <CardHeader
                        {...header}
                        sx={{
                            my: 1,
                            px: 3,
                            py: 1.25,
                            color: (theme: Theme) => theme.palette.primary.main,
                            ...header?.sx
                        }}
                    />
                ) : (<></>)}
                <CardContentComponent
                    {...CardContentProps}
                    sx={{
                        borderTop: '0.5px solid rgba(0, 0, 0, 0.12)',
                        ...(dialog ? ({ overflowY: 'auto' }) : null),
                        ...CardContentProps?.sx
                    }}
                >
                    <Collapse in={!!dataLoading} easing={'ease'}><Box><Fade in={!!dataLoading}>
                        <LoadingComponent py={4} {...LoadingProps} ref={null}/>
                    </Fade></Box></Collapse>
                    <Collapse in={!dataLoading && Object.keys(outsideErrors || {}).length > 0} timeout={{ exit: 0 }} mountOnEnter={true} unmountOnExit={true}><Box><Fade in={Object.keys(outsideErrors || {}).length > 0}>
                        <ErrorComponent py={4} {...outsideErrors} ref={null}/>
                    </Fade></Box></Collapse>
                    <Collapse in={!dataLoading && !(Object.keys(outsideErrors || {}).length > 0)}  easing={'ease'} timeout={{ enter: 0 }}><Box>
                        <Fade in={!dataLoading}>
                            <Box
                                sx={{ px: 1 }}
                                {...SectionDataWrapperProps}
                                component={Grid}
                                container={true}
                            >
                                {(fields && fields.length) ? fields.map((section, sectionIndex) => (
                                    <Fragment
                                        key={hashCode([(section?.length > 0) ?
                                            section.map(({ name }) => name) :
                                            sectionIndex
                                        ].join('.'))}
                                    >
                                        <Grid
                                            container={true}
                                            spacing={![false].includes(SectionsUniqueProps?.[sectionIndex]?.container) ? 2 : undefined}
                                            alignItems={'stretch'}
                                            justifyContent={'center'}
                                            my={0}
                                            {...SectionsProps}
                                            {...SectionsUniqueProps?.[sectionIndex]}
                                        >
                                            <>
                                                {section.length ? section.map(({ GridProps, component: Component = FormField, ...field }) => (
                                                    <Grid
                                                        item={true}
                                                        xs={12}
                                                        key={itemKeyExtractor && itemKeyExtractor(field)}
                                                        {...{ ...GridItemsProps, ...GridProps }}
                                                    >
                                                        <Component
                                                            register={register}
                                                            onChange={handleValueChange(field.name)}
                                                            onChangeBoolean={handleCheckedChange(field.name)}
                                                            error={getNestedObject(errors, field.name)}
                                                            loading={dataLoading}
                                                            {...{ ...ItemsProps, ...field }}
                                                            value={getNestedObject(values, field.name)}
                                                            {...(readOnly ? {
                                                                readOnly: readOnly
                                                            } : null)}
                                                        />
                                                    </Grid>
                                                )) : <></>}
                                            </>
                                        </Grid>
                                        {(sectionIndex < (fields.length - 1)) ? (
                                            <Divider
                                                sx={{ my: 3 }}
                                                {...DividerProps}
                                            />
                                        ) : (<></>)}
                                    </Fragment>
                                )) : (<></>)}
                            </Box>
                        </Fade>
                    </Box>
                    </Collapse>
                </CardContentComponent>
                <CardActionsComponent
                    {...CardActionsProps}
                >
                    <Grid
                        container={true}
                        spacing={2}
                        alignItems={'center'}
                        justifyContent={'space-evenly'}
                        {...CardActionsGridProps}
                        sx={{
                            p: 0,
                            flexDirection: { xs: 'column', sm: 'row' },
                            m: '0!important',
                            ...CardActionsGridProps?.sx
                        }}
                    >
                        {Object.entries<{ [key: string]: any }>(actions)
                            .filter(([key, { isAllowed }]) => ![false].includes(isAllowed))
                            .map(([key, { order, GridProps, component: Component = FormActionButton, ...action }]) => (
                                <Grid
                                    item={true}
                                    order={order}
                                    key={hashCode((key || action.text || order) as string)}
                                    {...CardActionsGridItemProps}
                                    {...GridProps}
                                    sx={{
                                        p: '8px!important',
                                        ...GridProps?.sx
                                    }}
                                >
                                    <Component
                                        error={Object.keys(errors).length > 0 ? errors : undefined}
                                        {...action}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </CardActionsComponent>
            </FormCardHeader>
        </>
    );
};

/**
 * Composant gérant les formulaires.
 *
 * @example
 * import FormCard from 'components/form/FVFormCard';
 *
 * <FormCard
 *	    header={{ title: t('...') }}
 *	    data={data}
 *	    readOnly={!data?.canUpdate}
 *	    fields={[
 *	        [
 *	            {
 *	                label: t('data.name'),
 *	                name: 'name',
 *	                GridProps: { xs: 12 },
 *	                required: true,
 *	                ...
 *	                //les props ici sont transmisent au composant utilisé, soit avec la propriété component soit a FVFormField
 *	            },
 *	            {
 *	                //autre champs
 *	            }
 *	        ],
 *	        [
 *	            //le format double array permet de séparer les champs et rajoute un séparateur (comportement pouvant être surchargé avec SectionsProps)
 *	        ]
 *	    ]}
 *	    getValidationRule={({ name }) => rules[name]}
 *	    actions={{
 *	        submit: {
 *	            text: t('...'),
 *	            variant: 'contained',
 *	            type: 'submit'
 *	        }
 *	    }}
 *	    onSubmit={handleSubmit}
 *	    submitLoading={submitLoading.value}
 *	    ref={actionRef} //<-- permet d'acceder aux fonctions de l'interface ActionDialogStateHandle
 *	    {...props}
 *      //plus de props disponibles
 * />
 */
const FormCardWithRef = forwardRef(FormCard) as
    <TDataShape extends { [key: FormFieldProps['name']]: FormFieldProps['value'] }>(p: FormCardProps<TDataShape> & { ref?: ForwardedRef<ActionDialogStateHandle<TDataShape>> }) => ReactElement;

export default FormCardWithRef;
export { FormCardWithRef as FormCard };
export type { ActionDialogStateHandle, FormCardProps, TField };

