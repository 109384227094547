import FormCard from 'components/form/FVFormCard';
import useTranslation from 'hooks/useTranslation';
import createFastContext from 'hooks/createFastContext';
import { useRouter } from 'next/router';
import { memo, type ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useBoolean } from 'usehooks-ts';
import useApi from 'hooks/useApi';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';


const { FastProvider, useFastStore } = createFastContext<{ [key: string]: boolean }>({});


const PreventDataLoss = ({ loading }: { loading?: boolean }) => {
    const router = useRouter();
    const { t } = useTranslation();

    const openConfirmationDialog = useBoolean(false);
    const dontAskAgain = useBoolean(false);
    const nextRoute = useRef<string>(router.asPath);

    const [dirtyFields, _, reset] = useFastStore(store => store);

    const confirmationLocked = useRef<boolean>(false);
    const needsConfirmation = useMemo(() => {
        if (confirmationLocked.current) { return false; }
        return dirtyFields ? Object.values(dirtyFields).reduce((a, c) => a || !!c, false) : false;
    }, [dirtyFields]);

    const handleTrigger = useCallback((route: string) => {
        if (!dontAskAgain.value) {
            confirmationLocked.current = true;
            openConfirmationDialog.setTrue();
            nextRoute.current = route;
            return Promise.resolve(true);
        }
    }, [openConfirmationDialog, dontAskAgain]);

    /**
     * submit = retour form
     */
    const handleBack = useCallback(() => {
        openConfirmationDialog.setFalse();
        confirmationLocked.current = false;
        return Promise.resolve(true);
    }, [openConfirmationDialog]);

    const cancelNotify = useCallback(() => {
        openConfirmationDialog.setFalse();
        return Promise.resolve(() => reset());
    }, [openConfirmationDialog, reset]);

    const handleContinue = useCallback(({ dontAskAgain: arg }: any) => {
        return cancelNotify().then((next) => {
            dontAskAgain.setValue(arg);
            const pr = nextRoute?.current ? router.push(nextRoute.current) : (() => {
                router.reload();
                return true;
            })();
            return Promise.resolve(pr).finally(() => {
                confirmationLocked.current = false;
                return next();
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelNotify, dontAskAgain]);

    const onRouteChangeStart = useCallback((route: string) => {
        if (needsConfirmation && !confirmationLocked.current) {
            handleTrigger(route);
            router.events.emit('routeChangeError');
            throw Error('Enforcing prevent route change (this warning can be safely ignored)');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleTrigger, needsConfirmation]);


    useEffect(() => {
        if (!loading && needsConfirmation) {
            router.events.on('beforeHistoryChange', onRouteChangeStart);
            return () => {
                router.events.off('beforeHistoryChange', onRouteChangeStart);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onRouteChangeStart, needsConfirmation, loading]);

    return (
        <>
            {!dontAskAgain.value ? (
                <FormCard
                    dialog={true}
                    open={openConfirmationDialog.value}
                    header={{
                        title: t('layout.form.prevent_data_loss.title'),
                        subheader: t('layout.form.prevent_data_loss.subtitle')
                    }}
                    DialogProps={{
                        maxWidth: 'sm'
                    }}
                    CardContentProps={{
                        sx: {
                            borderTop: 0
                        }
                    }}
                    data={{
                        dontAskAgain: false
                    }}
                    onSubmit={handleContinue}
                    submitLoading={!openConfirmationDialog.value}
                    actions={{
                        back: {
                            text: t('layout.form.prevent_data_loss.back'),
                            variant: 'text',
                            onClick: handleBack,
                            order: 1
                        },
                        submit: {
                            text: t('layout.form.prevent_data_loss.continue'),
                            variant: 'contained',
                            type: 'submit',
                            order: 2
                        }
                    }}
                />
            ) : (<></>)}
        </>
    );
};


const MemoizedPreventDataLoss = memo(PreventDataLoss);

interface PreventDataLossProviderProps {
    children: ReactElement | ReactElement[],
    loading?: boolean
}

const PreventDataLossProvider = ({ children, loading }: PreventDataLossProviderProps) => {
    const { authenticated } = useApi();
    return (
        <>
            <Fade in={authenticated} mountOnEnter={true} unmountOnExit={true}>
                <Box>
                    <FastProvider>
                        <>
                            {children}
                            <MemoizedPreventDataLoss loading={loading}/>
                        </>
                    </FastProvider>
                </Box>
            </Fade>
            <Fade in={!authenticated} mountOnEnter={true} unmountOnExit={true}>
                <Box>
                    {children}
                </Box>
            </Fade>
        </>
    );
};


export default PreventDataLossProvider;
export {
    PreventDataLossProvider,
    useFastStore as useDataLossProvider
};
export type {
    PreventDataLossProviderProps
};


