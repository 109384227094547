import LoadingButton, { type LoadingButtonProps } from '@mui/lab/LoadingButton';
import { type LinkProps } from '@mui/material/Link';
import { type TypographyProps } from '@mui/material/Typography';
import { forwardRef, type ElementType, type ForwardedRef, type ReactElement } from 'react';

interface FormActionButtonProps extends LoadingButtonProps {
    isAllowed?: boolean,
    text?: string, 
    order?: number,
    TextProps?: Partial<(TypographyProps | LinkProps) & { component: ElementType }>,
    title?: string,
    onClick?: any,
    [key: string]: any,
    children?: ReactElement | ReactElement[]
}
const FormActionButton = ({ text, TextProps, title, children, isAllowed = true, ...props }: FormActionButtonProps, ref: ForwardedRef<HTMLButtonElement> )=>  {

    return isAllowed ? (
        <LoadingButton 
            variant={'text'}
            {...props}
            ref={ref}
        >
            <>
                {text ? (
                    text
                ) : <></>}
                {children}
            </>
        </LoadingButton>
    ) : (<></>);
};

const FormActionButtonWithRef = forwardRef(FormActionButton) as
(p: FormActionButtonProps & { ref?: ForwardedRef<HTMLButtonElement> }) => ReactElement;



export default FormActionButtonWithRef;
export {
    FormActionButtonWithRef as FormActionButton
};
export type {
    FormActionButtonProps
};

