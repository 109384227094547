import CardLayout from 'components/layout/CardLayout';
import Dialog from '@mui/material/Dialog';
import { type PaperProps } from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import { type TransitionProps } from '@mui/material/transitions';
import { type ElementType, type ForwardedRef, forwardRef, type ReactElement } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';

interface FormCardDialogTransitionProps extends TransitionProps {
    children: ReactElement
}
const FormCardDialogTransition = (
    props: FormCardDialogTransitionProps,
    ref: ForwardedRef<unknown>
) => {
    return (
        <Fade
            mountOnEnter={true}
            unmountOnExit={true}
            {...props}
            ref={ref}
        />
    );
};

const FormCardDialogTransitionWithRef = forwardRef(FormCardDialogTransition) as
    (p: FormCardDialogTransitionProps & { ref?: ForwardedRef<unknown> }) => ReactElement;


interface FormCardHeaderProps {
    dialog?: boolean,
    FormProps?: BoxProps & {
        noForm?: boolean
    },
    PaperProps?: PaperProps,
    open?: boolean,
    [key: string]: any,
    children?: ReactElement | ReactElement[]
}
const FormCardHeader = ({
    dialog,
    FormProps: {
        noForm,
        ...FormProps
    } = {},
    children,
    PaperProps,
    ...props
}: FormCardHeaderProps) => {

    return (
        <>
            {dialog ? (
                <Dialog
                    open={false}
                    TransitionComponent={FormCardDialogTransitionWithRef}
                    color={'transparent'}
                    scroll={'paper'}
                    fullWidth={true}
                    maxWidth={'lg'}
                    PaperProps={{
                        ...PaperProps,
                        sx: {
                            overflow: 'hidden',
                            ...PaperProps?.sx
                        }
                    }}
                    {...props}
                >
                    <Box
                        {...(!noForm ? ({
                            component: 'form' as ElementType,
                            noValidate: true,
                            autoComplete: 'off'
                        }) : null )}
                        {...FormProps}
                        sx={{
                            overflowY: 'hidden',
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            ...FormProps?.sx
                        }}
                    >
                        {children}
                    </Box>
                </Dialog>
            ) : (
                <>
                    <Box
                        {...(!noForm ? ({
                            component: 'form' as ElementType,
                            noValidate: true,
                            autoComplete: 'off'
                        }) : null )}
                        {...FormProps}
                    >
                        <CardLayout
                            {...props}
                        >
                            {children}
                        </CardLayout>
                    </Box>
                </>
            )}
        </>
    );
};

export default FormCardHeader;
export {
    FormCardHeader
};
export type {
    FormCardHeaderProps
};

