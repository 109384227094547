import { createContext, type ReactElement, useCallback, useContext, useMemo, useRef } from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

interface SetOptions {
    replace: boolean
}


interface FastContextProps<Store> {
    get: () => Store,
    set: (value: Partial<Store>, opt?: Partial<SetOptions>) => void,
    subscribe: (callback: () => void) => () => void,
    reset: () => void
}
const defaultFastContext: FastContextProps<null> = {
    get: () => null,
    set: () => null,
    subscribe: () => () => null,
    reset: () => null
};

interface FastProviderProps<Store> {
    children: ReactElement,
    initialStore?: Store
}

/**
 * Creation générique de Fast Context: permet de limiter les maj des composants à uniquement ceux utilisant useFastStore
 * @example
 * //<fichier_parent>.tsx
 * const { FastProvider, useFastStore } = createFastContext(variable);
 * const ParentComponent = () => {
 *  ...
 *  return (<FastProvider>...</FastProvider>)
 * }
 *
 * export { ParentComponent, useParentStore: useFastStore }
 *
 * //<fichier_enfant>.tsx
 * import { useParentStore } from '<fichier_parent>.tsx'
 * const ChildComponent = ({value}: { value: '' }) => {
 *     const [fieldValue, setStore] = useParentStore((store) => store[value]);
 *     ...
 * }
 */
const createFastContext = <Store extends { [key: string]: unknown }>(initialState: Store) => {
    const FastContext = createContext<FastContextProps<any>>(defaultFastContext);
    const FastProvider = ({ children, initialStore = initialState }: FastProviderProps<Store>) => {
        const store = useRef<Store>(initialStore);

        const get = useCallback(() => store.current, []);
        const subscribers = useRef(new Set<() => void>());
        const updateSubscribers = useCallback(() => {
            subscribers.current.forEach((callback) => callback());
        }, []);
        const set = useCallback((value: Partial<Store>, { replace }: Partial<SetOptions> = {}) => {
            store.current = (!replace ? ({ ...store.current, ...value }) : (value)) as Store;
            updateSubscribers();
        }, [updateSubscribers]);

        const subscribe = useCallback((callback: () => void) => {
            subscribers.current.add(callback);
            return () => subscribers.current.delete(callback);
        }, []);

        const reset = useCallback(() => {
            store.current = initialState;
            return updateSubscribers();
        }, [updateSubscribers]);

        const contextValue = useMemo(() => ({
            get,
            set,
            subscribe,
            reset
        }), [get, set, subscribe, reset]);

        return (
            <FastContext.Provider value={contextValue}>
                {children}
            </FastContext.Provider>
        );
    };
    //! Attention à (la virgule ou extends unknown) dans le typage => permet d'échapper la priorité du format .tsx qui considère la balise comme un tag jsx
    const useFastStore = <SelectorOutput,>(
        selector: (_: Store) => SelectorOutput
    ) => {
        const store = useContext(FastContext);

        const state = useSyncExternalStoreWithSelector(
            store.subscribe,
            store.get,
            () => initialState,
            selector
        );

        return [state, store.set, store.reset] as [typeof state, typeof store.set, typeof store.reset];
    };

    return {
        FastProvider,
        useFastStore
    };
};

export default createFastContext;
export { createFastContext };
export type {
    FastContextProps,
    FastProviderProps,
    SetOptions
};
