import Alert from '@mui/material/Alert';
import Stack, { type StackProps } from '@mui/material/Stack';
import { type ForwardedRef, forwardRef, type ReactElement } from 'react';
import useTranslation from 'hooks/useTranslation';
import Typography from '@mui/material/Typography';

interface ErrorComponentProps extends Partial<StackProps> {
    children?: ReactElement | ReactElement[],
    message?: string
}
const ErrorComponent = ({ children, message = '', ...props }: ErrorComponentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    return (
        <>
            <Stack 
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
                width={'100%'}
                overflow={'hidden'}
                {...props}
                ref={ref}
            >
                <Alert
                    severity={'error'}
                    variant={'outlined'}
                    sx={{ border: 'none' }}
                >
                    <Typography fontSize={16}>{t(message)}</Typography>
                </Alert>
                {children}
            </Stack>
        </>
    );
};

const ErrorComponentWithRef = forwardRef(ErrorComponent) as
    (props: ErrorComponentProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;

export default ErrorComponentWithRef;
export type {
    ErrorComponentProps
};

