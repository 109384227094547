import NavLink from 'components/menu/NavLink';
import useTranslation from 'hooks/useTranslation';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Stack, { type StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { type ForwardedRef, forwardRef, type ReactElement } from 'react';
import { useBoolean, useDebounce, useEffectOnce, useIsMounted } from 'usehooks-ts';


interface LoadingComponentProps extends StackProps {
    displayDebounceAction?: boolean,
    debounceActionDelay?: number,
    children?: ReactElement | ReactElement[]
}
const LoadingComponent = ({
    children,
    displayDebounceAction = false,
    debounceActionDelay = 5000,
    ...props
}: LoadingComponentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const isMounted = useIsMounted();
    const { t } = useTranslation();

    const displayAction = useBoolean(false);
    const debouncedDisplayAction = useDebounce(displayAction.value, debounceActionDelay);
    useEffectOnce(() => {
        if (isMounted()) {
            displayAction.setTrue();
        }
    });
    
    return (
        <>
            <Stack 
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
                width={'100%'}
                my={'auto'}
                overflow={'hidden'}
                {...props}
                ref={ref}
            >
                <CircularProgress />
                {children}
                {displayDebounceAction ? <Fade in={debouncedDisplayAction}>
                    <Stack
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                    >
                        <Typography>{t('layout.loading.stuck.1')}</Typography>
                        <NavLink href={'/'} passHref={true}>
                            <Link>{t('layout.loading.stuck.2')}</Link>
                        </NavLink>
                    </Stack>
                </Fade> : <></>}
            </Stack>
        </>
    );
};

const LoadingComponentWithRef = forwardRef(LoadingComponent) as
    (props: LoadingComponentProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;

export default LoadingComponentWithRef;
export type {
    LoadingComponentProps
};

