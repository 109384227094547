import { useCallback } from 'react';

const useGetNestedObject = () => {
    const getNestedObject = useCallback((nestedObj: any, path?: string) => {
        if (!path) return nestedObj;
        return path.split('.').reduce(
            (obj, key) => ((obj != '' && obj != undefined && ![undefined].includes(obj?.[key])) ? obj[key] : ''),
            nestedObj
        ) ;
    }, []);

    const rebuildNestedObject = useCallback((path: string, value: any) => {
        return path.split('.').reduceRight((a, c) => ({ [c]: a }), value);
    }, []);

    const mapToValue = useCallback((obj: Map<any, any>) => {
        return (
            Object.entries<any>(
                Object.fromEntries(obj)
            ).reduce((a, [key, value]) => {
                return { ...a, ...rebuildNestedObject(key, value?.value) };
            }, {})
        );
    }, [rebuildNestedObject]);

    return ({
        getNestedObject,
        rebuildNestedObject,
        mapToValue
    });
};

export default useGetNestedObject;
export {
    useGetNestedObject
};

