import Box, { type BoxProps } from '@mui/material/Box';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import { forwardRef, type ForwardedRef, type ReactElement } from 'react';

interface LinkProps {
    href: string,
    labelT?: string,
    icon?: ReactElement | any,
    tag?: string,
    [key: string]: any
}


interface NavLinkProps extends Partial<NextLinkProps> {
    href?: string | any,
    NextLinkProps?: Partial<NextLinkProps>,
    children?: ReactElement,
    passHref?: boolean,
    withQueryParams?: boolean,
    BoxProps?: Partial<BoxProps>,
    sx?: any,
    target?: '_blank'
}
const NavLink = ({ href, passHref, children, NextLinkProps, sx, BoxProps, target, ...props }: NavLinkProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <>
            <Box
                {...BoxProps}
                sx={{ ['& a']: { textDecoration: 'none', maxWidth: 'inherit' }, ...BoxProps?.sx, ...sx }}
                ref={ref}
            >
                {href ?
                    target == '_blank' ?
                        <NextLink
                            shallow={false}
                            href={href}
                            passHref={passHref}
                            legacyBehavior={passHref}
                            {...props}
                            {...NextLinkProps}
                        >
                            <a target="_blank">{children}</a>
                        
                        </NextLink>
                        :
                        <NextLink
                            shallow={false}
                            href={href}
                            passHref={passHref}
                            legacyBehavior={passHref}
                            {...props}
                            {...NextLinkProps}
                        >
                            {children}
                        </NextLink>
                    :
                    <>{children}</>
                }
            </Box>
        </>
    );
};

const NavLinkWithRef = forwardRef(NavLink) as
    (p: NavLinkProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;


export default NavLinkWithRef;
export {
    NavLinkWithRef as NavLink
};
export type {
    LinkProps, NavLinkProps
};

