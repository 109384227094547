import { AuthContext, type AuthContextProps } from 'contexts/AuthContext';
import { useContext } from 'react';

const useApi = () => useContext(AuthContext) as AuthContextProps;

export default useApi;
export {
    useApi
};

