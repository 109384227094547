import { uuid } from '../utils/random';
import { useCallback, useMemo } from 'react';
import { useDataLossProvider } from '../contexts/PreventDataLossContext';
import useSWR from 'swr';

interface PreventDataLossOptionsProps {
    enabled?: boolean,
    id?: string
}
const usePreventDataLoss = (isDirty: any = false, { enabled = true, id = uuid() }: PreventDataLossOptionsProps = {}) => {
    const [currentDirty, setIsDirty, reset] = useDataLossProvider(store => enabled ? store : null);

    const withoutCurrent = useMemo(() => {
        const { [id]: _current, ...rest } = ({ [id]: null, ...currentDirty });
        return rest;
    }, [currentDirty, id]);

    const toggleIsDirty = useCallback((localDirty: any) => {
        if (enabled) {
            if (localDirty) {
                return setIsDirty({ [id]: localDirty });
            }
            return setIsDirty(withoutCurrent, { replace: true });
        }
    }, [enabled, id, withoutCurrent, setIsDirty]);

    useSWR(({ isDirty }), async ({ isDirty: isd }) => {
        if (isDirty != currentDirty?.[id]) {
            toggleIsDirty(isd);
        }
        return () => toggleIsDirty(false);
    }, {
        fallbackData: () => null,
        revalidateOnMount: true,
        revalidateIfStale: true,
        dedupingInterval: 0
    });


    const handleReset = useCallback(({ all = false }: { all?: boolean } = {}) => {
        return enabled ? all ? reset() : setIsDirty(withoutCurrent, { replace: true }) : null;
    }, [enabled, withoutCurrent, reset, setIsDirty]);

    const dirty = useMemo(() => [
        currentDirty,
        toggleIsDirty,
        handleReset
    ] as const, [currentDirty, toggleIsDirty, handleReset]);
    return dirty;
};

export default usePreventDataLoss;
export type {
    PreventDataLossOptionsProps
};