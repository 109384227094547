import Box, { type BoxProps } from '@mui/material/Box';
import Card, { type CardProps } from '@mui/material/Card';
import { type ReactElement } from 'react';


interface CardLayoutProps extends CardProps {
    children?: ReactElement | ReactElement[],
    component?: BoxProps['component']
}
const CardLayout = ({ sx, children, elevation = 0, ...props }: CardLayoutProps) => {
    return (
        <>
            <Box
                component={Card}
                elevation={elevation}
                variant={(elevation == 0) ? 'outlined' : 'elevation' }
                color={'background'}
                sx={{
                    borderRadius: 2,
                    width: '100%',
                    ['& + &']: {
                        mt: 8
                    },
                    ...sx
                }}
                {...props}
            >
                {children}
            </Box>
        </>
    );
};

export default CardLayout;
export {
    CardLayout
};
export type {
    CardLayoutProps
};

