import useTranslation from 'hooks/useTranslation';
import Box from '@mui/material/Box';
import FormControl, { type FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel, { type InputLabelProps } from '@mui/material/InputLabel';
import OutlinedInput, { type OutlinedInputProps } from '@mui/material/OutlinedInput';
import {
    type ChangeEvent,
    type ForwardedRef,
    forwardRef,
    type ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { type FieldValues, type UseFormRegister } from 'react-hook-form';
import { useDebounce, useIsMounted, useIsomorphicLayoutEffect } from 'usehooks-ts';
import Collapse from '@mui/material/Collapse';
import { mergeRefs } from 'react-merge-refs';


interface FormFieldProps extends OutlinedInputProps {
    label?: string,
    value?: any,
    name: string,
    register?: UseFormRegister<FieldValues>,
    error?: any,
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
    loading?: boolean,
    readOnly?: boolean,
    FormControlProps?: FormControlProps,
    InputLabelProps?: InputLabelProps,
    maxLength?: number,
    [key: string | number | symbol]: any
}

/**
 * Composant pour l'affichage des champs de formulaire
 * @param {string} label
 * @param {string} value 
 * @param {UseFormRegister<FieldValues>} register - valeur renvoyée par useForm permettant de gérer la validation
 * @param error - correspond à errors[name] du errors renvoyé par useForm
 * @param handleChange - devrait appeler clearErrors de useForm et setValue pour value passé en param
 */
const FormField = ({ 
    label, 
    value = '', 
    name, 
    register,
    registerOpt,
    error, 
    onChange, 
    onChangeBoolean,
    loading, 
    required,
    FormControlProps,
    InputLabelProps, 
    TextFieldProps,
    FormHelperTextProps,
    readOnly,
    maxLength,
    inputProps,
    sx,
    ...props 
}: FormFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [idfier] = useState(`${[label || '', name || ''].map((key: string) => key?.trim()?.toLowerCase() || null).join('.')}`);
    const { t } = useTranslation();
    const isMounted = useIsMounted();
    const [currentValue, setCurrentValue] = useState<typeof value>(value);

    const localRef = useRef(null);
    const handleChange = useCallback((event: ChangeEvent<any>) => {
        setCurrentValue(event.target.value);
    }, []);

    useEffect(() => {
        if (isMounted() && value != currentValue) {
            setCurrentValue(value);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, isMounted]);

    const debouncedCurrentValue = useDebounce(currentValue, 0);
    useIsomorphicLayoutEffect(() => {
        if (![undefined, null].includes(debouncedCurrentValue)) {
            if (onChange && isMounted() && value != debouncedCurrentValue) onChange({ target: {
                value: debouncedCurrentValue
            } } as ChangeEvent<HTMLInputElement>);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedCurrentValue, isMounted]);

    const { ref: registerRef, ...registerProps } = useMemo(() => ({
        ref: undefined,
        ...(register && register(name, registerOpt))
    }), [register, name, registerOpt]);

    return (
        <>
            <FormControl 
                variant={'outlined'}
                error={!!error}
                required={required}
                disabled={readOnly || props?.disabled}
                {...FormControlProps}
                sx={{ 
                    px: 'auto', 
                    width: '100%',
                    mb: '1rem',
                    ...FormControlProps?.sx,
                    ...(maxLength ? ({
                        ['&:before']: {
                            content: `'${value?.length || 0}/${maxLength}'`,
                            position: 'absolute',
                            width: '100%',
                            top: 0,
                            right: 0,
                            textAlign: 'right',
                            pt: 0.5,
                            pr: 0.5,
                            color: 'disabled.dark',
                            fontSize: 12
                        }
                    }) : null)
                }} 
            >
                {!FormControlProps?.hiddenLabel ? <InputLabel 
                    htmlFor={`comp-${idfier}`}
                    sx={{ 
                        fontSize: '1rem'
                    }}
                    {...InputLabelProps}
                >
                    {label}
                </InputLabel> : <></>}
                <Box
                    component={OutlinedInput}
                    id={`comp-${idfier}`}
                    label={!FormControlProps?.hiddenLabel ? loading ? t('layout.form.state.loading') : label : null}
                    required={required}
                    name={name}
                    type={'text'}
                    {...registerProps}
                    aria-describedby={`comp-${idfier}-err-text`}
                    autoComplete={'off'}
                    sx={{
                        fontSize: '1rem',
                        ...sx
                    }}
                    readOnly={readOnly || props?.disabled}
                    inputProps={{ maxLength, ...TextFieldProps?.inputProps, ...inputProps }}
                    {...TextFieldProps}
                    {...props}
                    value={![undefined, null].includes(currentValue) ? currentValue : ''}
                    onChange={handleChange}
                    ref={mergeRefs( [ref, localRef, (registerRef ? registerRef : null)])}
                />
                <Collapse in={!!error?.message}>
                    <FormHelperText
                        id={`comp-${idfier}-err-text`}
                        {...FormHelperTextProps}
                        sx={{
                            visibility: error ? 'visible' : 'collapse',
                            ...FormHelperTextProps?.sx
                        }}
                    >
                        {t(error?.message, { fallbackIfNotFound: 'layout.error.network.500' })}
                    </FormHelperText>
                </Collapse>
            </FormControl>
        </>
    );
};

const FormFieldWithRef = forwardRef(FormField) as
(p: FormFieldProps & { ref?: ForwardedRef<HTMLInputElement> }) => ReactElement;


export default FormFieldWithRef;
export {
    FormFieldWithRef as FormField
};
export type {
    FormFieldProps
};

